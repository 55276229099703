// Import variables
@import "../../../themes/reseau_carel/sass/custom/_vars.scss";

.biblio-abo-pop-up {

  .biblio-abo {
    background-color: $noir;
    padding: 20px 10px 10px 20px;

    .close-btn {
      padding: 0 20px 30px 0;
      text-align: end;
      .close {
        position: absolute;
        cursor: pointer;
      }
    }
  }

  .aligned-infos {
    display: flex;
    flex-wrap: wrap;
    width: initial;
    padding: 30px;
    transform: scale(.85);
    transition: transform .3s ease-in-out;
    flex-direction: row;

    @media (max-width: $small) {
      flex-direction: column;
      align-items: flex-start;
    }


    h2, p {
      margin: 0;
    }

    h2 {
      position: relative;
      padding-left: 3rem;

      @media (min-width: $small + 1) {
        width: 30%;
        margin-right: 3rem;
      }

      @media (max-width: $small-plus) {
        padding-left: 2rem;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 1rem;
        height: 1rem;
        content: '';
        background-color: $rouge;
        transform: translateY(-50%);
      }
    }
  }

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  pointer-events: none;
  overflow: auto;
  background-color: $background_color_6;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  z-index: 200;
}

.biblio-abo-pop-up.is-visible {
  pointer-events: auto;
  opacity: 1;

  .aligned-infos {
    transform: scale(1);
  }
}

@media screen and (max-width: 950px) {
  &:not(.trombinoscope-pop-up) {
    .aligned-infos {
      width: calc(100% - 20px);
    }
  }
}

.trombinoscope-pop-up {
  align-items: flex-start;

  .aligned-infos {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 1000px) {

  &:not(.trombinoscope-pop-up) {
    .aligned-infos {
      padding: 30px 10px;
    }
  }

  .aligned-infos {
    .more {
      right: 5px;
      bottom: 5px;
    }
  }
}

@media screen and (max-width: 450px) {
  .trombinoscope-pop-up {
    padding: 10px;
  }
}
