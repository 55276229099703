
	// Variables (Surcouche de knacss/_config/_variables.scss)
      // Fonts

      $font-base: 'roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
      $font-heading: 'roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
      $font-condensed: 'roboto condensed', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;

		// Couleur

      $noir:                  #000 !default;
      $blanc:                 #fff !default;
      $rouge:                 #fd0100 !default;
      $vert:                  #26a65b !default
      $error:                 #fd0100 !default;
      $success:               #26a65b !default;
      $background_color_6:    rgba(68, 68, 68, 0.8);

      // Tailles

      $tiny        : 480px !default;
      $tiny-plus   : 520px !default;
      $small       : 576px !default;
      $small-plus  : 680px !default;
      $medium      : 768px !default;
      $medium-plus : 830px !default;
      $large       : 992px !default;
      $large-plus  : 1050px !default;
      $extra-large : 1300px !default;
      $x-large-plus: 1600px !default;
      $xx-large    : 2020px !default;
